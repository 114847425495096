<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <h5>{{ question }}</h5>
            <slot name="subtitle"></slot>
            <ValidationProvider :rules="rules" v-slot="{ errors }">
              <template v-if="!responses">
                <div class="form-check form-check-inline">
                  <input
                    v-model="response"
                    class="form-check-input"
                    type="radio"
                    id="response1"
                    name="response"
                    :value="true"
                  />
                  <label class="form-check-label" for="response1">Oui</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="response"
                    class="form-check-input"
                    type="radio"
                    id="response2"
                    name="response"
                    :value="false"
                  />
                  <label class="form-check-label" for="response2">Non</label>
                </div>
                <div class="form-check form-check-inline">
                  <!-- <input v-model="response" class="form-check-input" type="radio" id="response2" name="response" :value="false">
                    <label class="form-check-label" for="response2">Non</label> -->
                  <i
                    v-tooltip.top="'Vider la sélection'"
                    class="pi pi-ban text-danger"
                    @click="response = null"
                    style="font-size: 1rem"
                  ></i>
                  Vider
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(resp, i) in responses"
                  :key="i"
                  class="form-check form-check-inline"
                >
                  <input
                    v-model="response"
                    class="form-check-input"
                    type="radio"
                    :id="`response${i + Math.floor(Math.random() * 1000)}`"
                    name="response"
                    :value="resp.value"
                  />
                  <label class="form-check-label" :for="`response${i}`">{{
                    resp.label
                  }}</label>
                </div>
              </template>

              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["value", "question", "rules", "responses"],
  data() {
    return {
      response: this.value,
    };
  },
  watch: {
    response() {
      this.$emit("input", this.response);
    },
  },
  methods: {},
};
</script>

<style scoped></style>
